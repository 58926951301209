.banner{position: relative; overflow: hidden; margin-top: 90px;padding: 0;height: 80vh;}
.overlay{position: absolute; background: transparent linear-gradient(90deg, #0D0F17 0%, #0D0F17FB 12%, #0D0F1700 100%); bottom: 0; right: 0; width: 50%; top: 0; z-index: 99;}
.mianBanner{ position: relative; z-index: 999;}
.wrapBanner{max-width: 860px;}
.wrapBannerBtn{display: flex; align-items: flex-end;}
.wrapBannerBtn div:not(:last-child){margin-right: 20px;}
.player-wrapper{position: absolute; right: 0;width: 45%;top: 0;height: 100%;}
.player-wrapper video{height: 100%;}
.player-wrapper .video-react-control-bar, .player-wrapper button{display: none;}
.player-wrapper >div {padding-top: 0px !important;}
.banner .customBox, .banner .customBox .row{height: 100%;}
.banner .sofia-p{max-width: 600px;font-weight: 300;}
@media (max-width:1699px) {
.banner{margin-top: 90px;}
.wrapBannerBtn div:not(:last-child){margin-right: 9px;}
}
@media (max-width:1399px) {
.wrapBanner { max-width: 550px; }
.banner{margin-top: 82px;}
}
@media (max-width:1199px) {
/* .player-wrapper  { position: unset; } */
}
@media (max-width:992px) {
.overlay, .player-wrapper{ width: 75%; }
}
@media (max-width:576px) {
.overlay, .player-wrapper{ width: 100%; }
.banner .sofia-p{max-width: 90%;}
}
    
