.dashboardHeader{padding: 40px 40px;}
.px40{padding: 0px 40px;}
.dashboardHeader h2{font-family: 'outfit';  font-size: 30px;  line-height: 80px;  font-weight: 500; color: #fff; margin-bottom: 0px;}
.box{background: #151721; border-radius: 20px; padding: 40px;height: 100%;}
.boxMb80{margin-bottom: 80px;}
.upload {margin: auto; text-align: center;}
.upload img {margin-bottom:40px;}
.upload .orange-btn{margin-top: 50px;}
.upload p{margin-bottom: 0px;}
.analytics h4{margin-bottom: 6px;}
/* .analytics p{font-family: 'outfit';  font-size: 16px;  line-height: 22px;  font-weight: 500; color: #fff; margin-bottom: 0px;} */
.analyticBox.box{padding-left:0px; padding-right:0px;}
.analytics .analyticSummary p{font-family: 'outfit';  font-size: 16px;  line-height: 48px;}
.analyticSummary ul{padding: 0px 40px; list-style: none; margin: 30px 0px;}
.analyticSummary ul li{display: flex; justify-content: space-between;}
.analyticBox.box > h2 ,.analyticBox.box .analytics > div, .analyticBox.box > ul { margin-left: 40px; margin-right: 40px;}
.analyticBox.box .analytics .analyticSummary{padding: 0px; border-bottom: 1px solid #585960; margin: 0px 0px 30px 0px;}
.analytics p{font-family: 'outfit';  font-size: 22px;  line-height: 48px;  font-weight: 500; color: #fff; margin-bottom: 0px;}
.count{display: block; font-family: 'outfit';  font-size: 42px;  line-height: 48px;  font-weight: 500; color: #fff; margin-top: 30px; opacity: 0.6;}
.analytics p.md-16{font-size: 16px;  line-height: 20px;}
.wrapVideoInfo ul{padding-left:0px;list-style: none;}
.wrapVideoInfo ul li .videoInfoDiv{display: flex; align-items: center;border-bottom: 1px solid #585960; padding: 20px 0px;}
.videoInfoDiv h4{font-family: 'outfit';  font-size: 16px;  line-height: 20px;  font-weight: 600; color: #fff; margin-bottom: 12px;opacity: 0.75;}
.videoInfoDiv p{font-family: 'outfit';  font-size: 14px;  line-height: 18px;  font-weight: 500; color: #fff; margin-bottom: 0px;opacity: 0.4;}
.videoContent{max-width: 150px; margin-left: 20px;}
/* .videoImg{border-radius: 8px;width: 150px; height: 90px;} */
/* .videoImg img{object-fit: cover; width: 150px; height: 90px;} */
.searchByDays{display: flex; align-items: center;}
.searchByDays svg{color: #FD6F21; font-size: 13px;}
.menuRotate svg{transform: rotate(-180deg)}
@media (max-width:1699px) {
.px40{padding: 0px 20px;}
.boxMb80 { margin-bottom: 40px;}
.box{ border-radius: 10px; padding: 30px;}
.dashboardHeader{padding: 20px 20px;}
.dashboardHeader h2{ font-size: 20px;  line-height: 24px; }
.analyticBox.box > h2 ,.analyticBox.box .analytics > div, .analyticBox.box > ul { margin-left: 20px; margin-right: 20px;}
.analyticBox.box .analytics .analyticSummary{margin: 0px 0px 20px 0px;}
.analytics p{ line-height: 22px; }
.upload .orange-btn { margin-top: 35px;}
.upload img { margin-bottom: 20px; width: 100px;}
.analyticSummary ul { padding: 0px 20px; margin: 15px 0px;}
.searchByDays svg{font-size: 10px;}
.analytics p.md-16{ font-size: 14px;  line-height: 18px;}
.analytics .analyticSummary p{ font-size: 14px;  line-height: 35px;}
.count{ font-size: 35px;  line-height: 29px;  margin-top: 25px;}
.wrapVideoInfo ul li .videoInfoDiv{padding: 10px 0px;}
.videoInfoDiv h4{ font-size: 14px;  line-height: 20px; margin-bottom: 9px;}
.videoInfoDiv p{ font-size: 12px;  line-height: 17px;}
.videoContent{max-width: 100px; margin-left: 10px;}
.searchByDays svg{ font-size: 10px;}
}
@media (max-width:576px) {
.dashboardHeader h2, .dashboardHeader button{text-align: center; margin: auto;}
}