.feature{position: relative;}
.feature .customBox { padding: 0 36px;}
.mainF-Row1{ padding: 347px 0px 177px 0px;position: relative;}
.dataLabelRow{position: relative;}
.classify ul{display: flex; margin-bottom: 0px; padding-left: 0px;list-style: none;}
.classify ul li{display:flex;}
.classify ul li:not(:last-child){margin-right: 62px;}
.classify ul li svg{margin-right: 5px; font-size: 30px; line-height: 0px; color: #FD6F21;}
.classify ul li p{font-family: 'outfit'; font-size: 20px; line-height: 31px; font-weight: 600;opacity: 0.75;}
.featureImg{ position: relative; z-index: 9999;}
.feature .bar{position: absolute; bottom: -23%; width: 290px; height: 100%; transform: skew(-48deg); overflow: hidden; background: transparent linear-gradient(174deg, #0D0F17 0%, #FD6F21 100%) 0% 0% no-repeat padding-box; opacity: 1; left: 130px;}
.classify{position:relative; z-index: 99;}
.classify p {max-width: 705px;}
.dataLabelImg{ position: relative; z-index: 999; margin-left: 56px;}
.wrapData{ margin-left: -190px;position: relative; z-index:999;}
.wrapData p {max-width: 642px;}
.wrapData .orange-btn{margin-top: 55px;}
.featureImg2 img{float: right;}
.wrap-path-lines{ position: absolute; left: 30%; top: -150px;z-index: 9999;}
.featureRow{position: relative;}
.wrap-path-lines .lines, .wrap-path-lines .line3{ display: block; height: 150px; width: 2px; border-left: 2px dashed #817a7a;}
.wrap-path-lines .line2{ position: relative; display: block; width: 550px; height: 2px; border-top: 2px dashed #817a7a;}
.wrap-path-lines .line3{ position: absolute; right: 0; bottom: 0;}
.dots{display: flex; flex-direction: column;position: absolute;}
.dots span:not(:last-child){margin-bottom: 27px;}
.dots span{display: block; width: 8px; height: 8px; background-color: #fff; border-radius: 50%;z-index: 999;}

@media (max-width:1699px) {
.wrapData h2{margin-bottom: 16px;}
.wrapData .orange-btn{margin-top: 30px;}
.dataLabelImg { margin-left: 25px;}
}
@media (max-width:1399px) {
.feature .customBox { padding: 0 25px;}
.wrap-path-lines .lines, .wrap-path-lines .line3 {  height: 95px;}
.wrap-path-lines .line2{width: 350px;}
.wrap-path-lines{top: -95px;}
.mainF-Row1{padding: 250px 0px 150px 0px;}
.classify ul li:not(:last-child) { margin-right: 20px;}
.classify ul li p {font-size: 14px; line-height: 19px;}
.classify ul li svg { margin-right: 3px; font-size: 20px;}
.dots span{width: 6px; height: 6px;}
.dots span:not(:last-child) { margin-bottom: 17px;}
.feature .bar{ width: 195px; transform: skew(-50deg); left: 107px;}
}
@media (max-width:1199px) {
.wrapData { margin-left: -100px; }
}
@media (max-width:767px) {
.wrapData { margin-left: 0px; }
.dots { top: -100px;}
.mainF-Row1 { padding: 200px 0px 150px 0px;}
}
@media (max-width:567px) {
.wrap-path-lines .line2 {width: 263px; }
}
@media (max-width:467px) {
.classify ul{    flex-direction: column; justify-content: center;  width: 100%; margin: auto;}
.classify ul li{margin: auto auto 10px auto; margin-right: auto;}
.classify ul li:not(:last-child) { margin-right: auto;}
.wrap-path-lines .line2 {width: 180px; }
.wrap-path-lines .lines, .wrap-path-lines .line3 { height: 66px;}
.wrap-path-lines {top: -69px;}
}
