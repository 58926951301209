.subtitles .analytic-P{margin-bottom: 16px;}
.subtitles .themeTable .videoInfo .light-p{font-family: 'Outfit'; font-size: 16px; line-height: 22px; font-weight: 300; opacity: 0.4;}
.videoPara{max-width: 600px;}
.fw3{font-weight: 300 !important; opacity: 0.4 !important;}


@media (max-width:1699px) {
.subtitles .analytic-P{margin-bottom: 10px;}
.subtitles .themeTable .videoInfo .light-p{font-size: 13px; line-height: 19px; }
}

