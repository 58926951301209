.wrapFlexBar{display: flex; justify-content: space-between;margin-bottom: 60px;}
.wrapVideos{ display: grid; grid-auto-rows: auto; grid-gap: 59px; grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); grid-column-gap: 16px;}
.wrapVideos .exploreCard{margin-bottom: 50px;}
.exploreImg img{border-radius: 20px; margin-bottom: 20px;    width: 100%;}
.userImg{display: inline-block; width: 48px; height: 48px;border-radius: 50%;}
.userImg img{width: 100%; height:100%; object-fit: cover;}
.exploreCard .cardFooter{display: flex; align-items: center;}
.exploreCard .cardFooter .videoReview{display: flex; flex-direction: column;}
.exploreCard .cardFooter p{font-family: 'outfit'; font-size: 20px; line-height: 25px; font-weight: 400; margin-bottom: 5px;}
.exploreCard .cardFooter span{font-family: 'outfit'; font-size: 14px; line-height: 18px; font-weight: 400;opacity: 0.4;}

/* MEDIA QUERY */
@media (max-width: 1899px) {
.exploreCard .cardFooter p{ font-size: 16px; line-height: 20px;margin-bottom: 3px;}
.exploreCard .cardFooter span{ font-size: 12px; line-height: 15px;}
}
@media (max-width: 1399px) {
.wrapFlexBar{margin-bottom: 40px;}
.exploreImg img { border-radius: 10px; margin-bottom: 12px;}
.wrapVideos { grid-gap: 25px; grid-template-columns: repeat(auto-fill, minmax(220px, 1fr)); grid-column-gap: 12px;}
.exploreCard .cardFooter p {font-size: 15px; line-height: 18px;}
.exploreCard .cardFooter span { font-size: 11px; line-height: 13px;}
.userImg { width: 35px; height: 35px;}
}
    