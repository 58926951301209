.header{background: #151721; position: fixed; width: 100%; top: 0; z-index: 99999;}
.navbar{padding: 19px 0px;}
.wrapHeaderBar{position: relative;width: 100%;}
.navbar-brand{padding: 0px;width: 140px;}
.navbar-expand-lg .navbar-toggler{display: block;}
.navbar-nav { flex-direction: row; justify-content: end; align-items: center; height: 100%; }
.navbar-collapse.collapse.show{height:100% !important;}
.navbar-collapse , .navbar-expand-lg .navbar-collapse{flex-basis: 100% !important; display: block !important; flex-grow: 1; position: absolute; top: 0px; bottom: 0; right: 60px; padding:0px 50px 0px 0px; background: #151721; width: 60%;}
.collapse:not(.show){position: absolute; top: auto; bottom: auto; right: -252px; width: 0; height: 0;}
.header .navbar-expand-lg .navbar-nav .nav-link {padding: 35px 0px; color: #fff; border-radius: 10px; font-size: 16px; line-height: 31px; font-weight: 500; text-transform: capitalize; transition: all ease-in-out .4s; }
.header .navbar-expand-lg .navbar-nav .nav-link:not(:last-child){margin-right: 70px;}
.header .navbar-expand-lg .navbar-nav .nav-link:hover{ color: #FD6F21;}
.collapse:not(.show) { display: none !important;}
.header .dropdown-item.active, .header .dropdown-item:active { background-color: #FC491E;}
.header .navbar-nav .dropdown-menu{width: 300px;}
.navbar>.container-fluid{flex-direction: column;}
.nav-flex{display: flex; justify-content: space-between; align-items: center; width: 100%;}
.navbar-toggler { padding: 0;font-size: 0; line-height: 0;border: 0px solid transparent; border-radius: 0;}
.navbar-toggler:focus{box-shadow: none;}
.nav-right-bar{display: flex;}
.nav-right-bar div:not(:last-child){margin-right:10px;}
.navBtns{display: flex; align-items: center; justify-content: center; width: 52px; height: 52px; background: #1F212F; border-radius: 10px; cursor: pointer;}
.navBtns  svg{color: #6e718b; font-size: 20px;}
.wrapSearch{display: flex; position: relative;}
.openSearch{position: absolute; right: 0; top:0; bottom:0; width: 550px; height: 52px; transition: 0.5s linear;}
.openSearch input{margin-bottom: 0px; height: 100% !important;}
.searchIcon svg{position: absolute; bottom: 0; top: 0; right: 10px; margin: auto; font-size: 20px; filter: brightness(0) invert(1); transition: all ease-in-out .4s; }
.searchIcon{cursor: pointer;}
.faBar {position: relative; height: 100%; width: 100%;     display: flex; flex-direction: column; justify-content: center; align-items: center;}
.faBar span{ display: block; width: 13px; height: 2px; background-color: #6e718b; margin: 2px 0px;transition: all .4s ease-in-out;}
.faCross span{position: absolute; right: 0; left: 0; top: 0; bottom: 0;margin: auto;transition: all .4s ease-in-out;}
.faCross span:nth-child(1){transform: rotate(-45deg);}
.faCross span:nth-child(2){visibility: hidden; display: none;}
.faCross span:nth-child(3){transform: rotate(45deg);}
/* MEDIA QURIES */
@media (max-width:1699px) {
.navbar{padding: 18px 0px;}
.navbar-brand{width: 180px;}
.navBtns{ width: 40px; height: 40px; border-radius: 8px;}
.navbar-collapse , .navbar-expand-lg .navbar-collapse{ right: 40px; }
.nav-right-bar div:not(:last-child){margin-right:8px;}
.navBtns  svg{font-size: 14px;}
.header .navbar-expand-lg .navbar-nav .nav-link {padding: 15px 0px;}
}
@media (max-width:1399px) {
.navbar-brand img{width: 150px;}
.navBtns{ width: 35px; height: 35px; border-radius: 5px;}
.nav-right-bar div:not(:last-child){margin-right:7px;}
.header .navbar-expand-lg .navbar-nav .nav-link:not(:last-child){margin-right: 50px;}
.header .navbar-expand-lg .navbar-nav .nav-link { padding: 8px 0px; font-size: 14px; line-height: 20px;}
.searchStyle, .openSearch{width: 450px;  height: 37px;}
 }
@media (max-width:767px) {
.header .navbar-expand-lg .navbar-nav .nav-link:not(:last-child){margin-right: 35px;}
.navbar-collapse, .navbar-expand-lg .navbar-collapse{ padding: 0px 20px 0px 0px;}
}
@media (max-width:567px) {
 .navbar-collapse , .navbar-expand-lg .navbar-collapse{width: 100%;}
} 