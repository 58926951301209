.dashboard-Sidebar {position: fixed; top: 0px; height: 100vh; z-index: 999;}
.MobileSidebar { position: fixed; top: 0px; z-index: 999; width: 100px; background: #151721; border-radius: 50px; border-bottom-right-radius: 50px; margin-top: 23px; left: 10px; height: 90vh;}
.dashboardFlex{display: flex; flex-direction: column; justify-content: space-between; background: #151721; width: 310px;}
.dashboard-sitePages{ float: right; height: 100%;}
.dashboardBody{background-color: #0D101C; height: 100%;}
.dashboard-main-width{width: calc(100% - 328px);}
.dashboard-width{width: calc(100% - 0px);}
.dashboardLogo{padding: 26px 0px 26px 40px; border-bottom: 1px solid #303341;}
.dashboardLogo img{width: 120px;}
.autoBar{height: 100vh; }
.user{padding: 40px 0px 100px 0px; z-index: 999; height: 100%; overflow-y: auto; overflow-x: hidden; display: flex; flex-direction: column; justify-content: space-between;}
.userLink{height: 100%;}
.user .orange-btn{margin-left: 40px;}
.userBlock{margin-bottom: 30px; text-align: center; display: inline-block; width: 100%;}
.userBlock .userImg{width: 150px; height: 150px; border-radius: 50%; margin-bottom: 20px;}
.userBlock .userImg img{object-fit: cover;}
.userBlock h3{font-family: 'outfit';  font-size: 24px;  line-height: 30px;  font-weight: 500; color: #fff; margin-bottom: 0px; padding:0px;}
.userBlock p{font-family: 'outfit';  font-size: 16px;  line-height: 20px;  font-weight: 500;opacity: 0.4;}
.dashboard-ul{padding-left: 0px; list-style: none;}
.dashboard-ul li a{padding: 25px 0px 25px 45px;}
.dashboard-ul li a{ display: block; font-family: 'outfit';  font-size: 16px;  line-height: 20px;  font-weight: 500; color: #fff; text-decoration: none; transition: all ease-in-out .4s;}
.dashboard-ul li a:hover, .dashboard-ul li a.active{background-color: #303341;}
.dashboard-sitePages h2{font-family: 'outfit';  font-size: 30px;  line-height: 38px;  font-weight: 500; color: #fff; margin-bottom:30px;}
.dashboard-sitePages h4{font-family: 'outfit';  font-size: 24px;  line-height: 30px;  font-weight: 500; color: #fff; margin-bottom: 20px;}
.dashboard-sitePages p{font-family: 'outfit';  font-size: 16px;  line-height: 22px;  font-weight: 300; color: #fff; opacity: 0.4;}
.dashboard-sitePages .dashboard-p{font-family: 'outfit';  font-size: 16px;  line-height: 22px;  font-weight: 500; color: #fff;opacity: 0.4;} 
.logoTrue{float: right; margin: 15px 0px; margin-left: -10px; font-size: 22px; color: #fff; cursor: pointer;  display: flex; align-items: center; justify-content: center; transition: all ease-in-out .4s; transition-delay: .2s; width: 55px; height: 55px; border-radius: 20px; background: #FD6F21; box-shadow: 0px 0px 5px 3px #0d0f17; 
 position: relative; z-index: -1;}
.dsahboardLogo{ transform: rotate(0deg);}
.dsahboardLogo-change {margin: 10px 0px 0px 80px;transform: rotate(360deg); }
.MobileSidebar .dashboard-ul li a{padding: 25px;text-align: center;}
.MobileSidebar .user{height: auto;}

@media (max-width:1699px) {
.dashboard-sitePages h2{ font-size: 20px;  line-height: 24px; margin-bottom: 15px;}
.dashboard-sitePages h4{font-size: 18px;  line-height: 22px;  margin-bottom: 10px;}
.dashboard-sitePages p, .dashboard-sitePages .dashboard-p{font-size: 12px;  line-height: 20px;} 
.dashboardFlex{width: 220px;}
.dashboard-main-width { width: calc(100% - 250px);}
.logoTrue{ margin: 10px 0px; margin-left: -10px; font-size: 16px; width: 40px; height: 40px; border-radius: 15px;}
.dsahboardLogo-change{margin-left: 55px;}
.MobileSidebar{width: 65px;}
.MobileSidebar .dashboardLogo > div{width: 35px; margin: auto;}
.MobileSidebar .dashboardLogo img{width: 100%; height: 100%; object-fit: cover;}
}
@media (max-width:1399px) {
.dashboardLogo{padding: 18px 0px 18px 23px;}
.dashboardLogo img{width: 100px;}
.dashboard-ul li a{font-size: 14px;  line-height: 18px; padding: 18px 0px 18px 23px;}
.dashboard-ul li a img{width: 17px;}
.user{padding: 25px 0px 100px 0px; }
.user .orange-btn{margin-left: 25px;}
.userBlock .userImg{width: 100px; height: 100px; margin-bottom: 10px;}
/* .dashboard-sitePages h2{ font-size: 20px;  line-height: 24px; margin-bottom: 15px;} */
.dashboard-sitePages h4{font-size: 16px;  line-height: 20px;  margin-bottom: 8px;}

/* .dashboard-sitePages p,.dashboard-sitePages .dashboard-p{font-size: 14px;  line-height: 20px;} 
.dashboardFlex{width: 250px;}
.dashboard-main-width { width: calc(100% - 250px); padding: 0px 30px;} */
.userBlock h3{font-size: 20px;  line-height: 26px; }
.userBlock p{font-size: 11px;  line-height: 14px; }

}