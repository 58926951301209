.terms-sec {
    margin-top: 90px;
    padding: 160px 0;
    box-shadow: 0px 3px 16px #0000000F;
    background-color: #1B1D27;
}

.terms-sec h1 {
    margin-bottom: 20px;
}

.terms-sec p {
    font-size: 30px;
    font-weight: 300;
    color: rgba(225, 225, 225, 0.75);
    max-width: 700px;
    font-family: 'SofiaPro';
}

.details-sec {
    padding: 100px 0;
}

.details-sec p {
    color: rgba(225, 225, 225, 0.6);
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 60px;
}

.details-sec .wrapper {
    background-color: #1B1D27;
    border-radius: 10px;
    padding: 30px;
    display: flex;
}

.details-sec .wrapper .img {
    margin-right: 40px;
}

.details-sec .wrapper .H2 {
    font-weight: 500;
    margin-bottom: 15px;
    line-height: 42px;
}

.details-sec .wrapper .content {
    width: calc(100% - 70px);
}

.details-sec .wrapper p {
    font-size: 16px;
    font-weight: 400;
    color: rgba(225, 225, 225, 0.6);
    margin-bottom: 0;
}

.details-sec .col-lg-6 {
    margin-bottom: 60px;
}

.details-sec .all-others h1 {
    margin-bottom: 80px;
}

.details-sec .all-others-content h4 {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 36px;
}

.details-sec .all-others-content p {
    margin-bottom: 60px;
}

.details-sec .governing-law-para {
    margin-bottom: 40px;
}

.details-sec .ul-list {
    border-left: 2px solid #FA6400;
}

.details-sec .ul-list ul {
    list-style: none;
}

.details-sec .ul-list ul li {
    font-size: 18px;
}

@media (max-width: 1599px) {
    .terms-sec {
        padding: 100px 0;
    }
    .terms-sec p {
        font-size: 26px;
    }
    .details-sec {
        padding: 80px 0;
    }
    .details-sec p,
    .details-sec .col-lg-6 {
        margin-bottom: 40px;
    }
    .details-sec .wrapper {
        padding: 20px;
    }
    .details-sec .all-others h1 {
        margin-bottom: 50px;
    }
    .details-sec .all-others-content h4 {
        margin-bottom: 10px;
    }
    .details-sec .all-others-content p {
        margin-bottom: 40px;
    }
    .details-sec p {
        font-size: 18px;
    }
    .details-sec .all-others-content h4 {
        font-size: 30px;
    }
}

@media (max-width: 1399px) {
    .terms-sec,
    .details-sec {
        padding: 70px 0;
    }
    .details-sec .wrapper .H2 {
        margin-bottom: 5px;
    }
    .details-sec .wrapper p {
        line-height: 24px;
    }
    .terms-sec {
        margin-top: 82px;
    }
    .details-sec .all-others h1 {
        margin-bottom: 40px;
    }
}

@media (max-width: 1199px) {
    .terms-sec p {
        font-size: 24px;
    }
    .details-sec .wrapper .img {
        margin-right: 20px;
    }
}

@media (max-width: 991px) {
    .terms-sec, .details-sec {
        padding: 50px 0;
    }
}

@media (max-width: 575px) {
    .terms-sec p {
        font-size: 20px;
        line-height: 24px;
    }
    .terms-sec, .details-sec {
        padding: 30px 0;
    }
    .details-sec p, .details-sec .col-lg-6 {
        margin-bottom: 30px;
    }
    .details-sec .all-others h1 {
        margin-bottom: 20px;
    }
    .details-sec .all-others-content h4 {
        font-size: 26px;
    }
    .details-sec .all-others-content p {
        margin-bottom: 30px;
    }
    .details-sec p {
        font-size: 16px;
        line-height: 26px;
    }
    .details-sec .ul-list ul {
        padding-left: 15px;
    }
    .details-sec .ul-list ul li {
        font-size: 16px;
        line-height: 24px;
    }
    .details-sec .wrapper .H2 {
        line-height: 26px;
    }
}