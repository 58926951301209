@font-face {
  font-family: 'outfit';
  src: url('./assets/fonts/Outfit-Black.woff2') format('woff2'),
       url('./assets/fonts/Outfit-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'outfit';
  src: url('./assets/fonts/Outfit-ExtraBold.woff2') format('woff2'),
       url('./assets/fonts/Outfit-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'outfit';
  src: url('./assets/fonts/Outfit-Bold.woff2') format('woff2'),
       url('./assets/fonts/Outfit-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'outfit';
  src: url('./assets/fonts/Outfit-SemiBold.woff2') format('woff2'),
       url('./assets/fonts/Outfit-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'outfit';
  src: url('./assets/fonts/Outfit-Medium.woff2') format('woff2'),
       url('./assets/fonts/Outfit-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'outfit';
  src: url('./assets/fonts/Outfit-Regular.woff2') format('woff2'),
       url('./assets/fonts/Outfit-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'outfit';
  src: url('./assets/fonts/Outfit-Light.woff2') format('woff2'),
       url('./assets/fonts/Outfit-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'outfit';
  src: url('./assets/fonts/Outfit-ExtraLight.woff2') format('woff2'),
       url('./assets/fonts/Outfit-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'outfit';
  src: url('./assets/fonts/Outfit-Thin.woff2') format('woff2'),
       url('./assets/fonts/Outfit-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'SofiaPro';
  src: url('./assets/fonts/SofiaProBold.woff2') format('woff2'),
       url('./assets/fonts/SofiaProBold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'SofiaPro';
  src: url('./assets/fonts/SofiaProLight.woff2') format('woff2'),
       url('./assets/fonts/SofiaProLight.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
body{
  background: #0D0F17;
  font-family: 'outfit';
  font-size: 20px;
  line-height: 30px;
  font-weight: 300;
  color: #fff;
  overflow-x: hidden;
}
/* Scroll Bar Styles */
* {
  scrollbar-width: thin;
  scrollbar-color: #FD6F21 #ccc;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background: #ffe4e6;
}
::-webkit-scrollbar-thumb {
  background-color: #FD6F21;
}
.form-select:focus{
  box-shadow: none;
  outline: none;
}
select option {
  background-color: rgba(250, 250, 250, 0.3);
  background: rgba(250, 250, 250, 0.3);
  color: #FD6F21;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

option:checked , select option:hover{
  color: #FD6F21 !important;
  background-color: #f2c9b3 !important;
}


.theme{
  color: #FD6F21;
}
.customBox-header{
  max-width: 1840px;
}
.customBox{
  max-width: 1722px;
}
section{
  overflow: hidden;
  padding: 120px 0px 0px 0px;
}
a{
  font-size: 20px;
  line-height: 31px;
  font-weight: 400;
  text-decoration: none;
}
.ellipse{
  display: block; 
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis;
}
.outfit-p{
  font-family: 'outfit';
  font-size: 20px;
  line-height: 30px;
  opacity: 0.4;
}
.sofia-p{
  font-family: 'SofiaPro';
  font-size: 24px;
  line-height: 36px;
  opacity: 0.69;
}
.sofia-p{
  font-size: 24px;
  line-height: 36px;
  opacity: 0.69;
}
.link{
  position: relative;
  font-family: 'outfit'; 
  font-size: 16px; 
  line-height: 20px; 
  font-weight: 700; 
  color: #fff;
  background: transparent;
  border: 0px;
  text-decoration: none;
  display: block;
  width: fit-content;
  text-decoration: none;
  border-radius: 0px;
  padding:0px;
  cursor: pointer;
  transition: all ease-in-out .4s;
}
.link:hover{color: #fff;}
.link.orangeLink{
  font-family: 'outfit'; 
  font-size: 20px; 
  line-height: 25px; 
  font-weight: 700; 
  color: #FD6F21;
}
.link.orangeLinkBtn{
  color: #FD6F21;
}
.orangeLink.link::before, .link.orangeLinkBtn::before{
  background: #FD6F21;
}
.link.themeLink{
  color: #FD6F21;
}
.link.themeLinkBtn{
  color: #FD6F21;
}
.themeLink.link::before, .link.themeLinkBtn::before{
  background: #FD6F21;
}
/* .theme-form .outer-p.link:hover{
  color: #FD6F21;
} */
.blueLink{
  display: block; 
  text-decoration: none;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 20px;
}
.link:hover:before {
  transform: scaleX(1);
}
.link::before {
  content: '';
  display: block;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  position: absolute;
  background: #fff;
  transform: scaleX(0);
  transition: all ease-in-out .4s;
}
.cuetomModelBtn{
  background: transparent;
  border: 0px;
  padding: 0px;
  margin: 0px;
  text-align: inherit;
}
.theme-btn, button{
  display: block;
  width: fit-content;
  font-family: 'outfit';
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  text-decoration: none;
  border-radius: 10px;
  padding: 23px 50px;
  text-decoration: none;
  transition: all ease-in-out .4s;
}
.orange-btn{
  color: #FC491E;
  background: transparent;
  border: 2px solid #FC491E;
  margin-top: 80px;
}
.orange-btn:hover{
  color: #fff;
  background: #FC491E;
}
.outline-btn{
  color: #FC491E;
  /* background: #F9B52B1A; */
  background: transparent;
  margin-top: 80px;
  border: 1px solid #FD6F2180;
}
.outline-btn:hover{
  color: #FC491E;
  background: #F9B52B1A;
  border: 1px solid #FD6F2180;
}
.black-btn{
  color: #fff;
  background: #1E222F;
  border: 2px solid transparent;
}
.grey-btn{
  color: #fff;
  background: #343648;
  border: 2px solid transparent;
}
.grey-btn:hover{
  color: #fff;
  background: #00000036;
  border-color: #343648;
}
/* .black-btn:hover{
  color: #FC491E;
  background: transparent;
  border-color: #FC491E;
} */
.italicLink{
  font-family: 'outfit';
  font-size: 24px;
  line-height: 72px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  opacity: 0.4;
  cursor: pointer;
  transition: all ease-in-out .4s;
}
.italicLink:hover{
  color: #FC491E;
  opacity: 1;
  text-decoration: underline;
}
.textBtn{
  font-family: 'outfit';
  font-size: 16px;
  line-height: 72px;
  font-weight: 400;
  text-transform: uppercase;
  opacity: 0.5;
}
h1{
  font-size: 60px;
  line-height: 72px;
  font-weight: 600;
  margin-bottom: 30px;
}
h2{
  font-size: 60px;
  line-height: 76px;
  font-weight: 700;
  margin-bottom: 30px;
}
.H2{
  font-size: 42px;
  line-height: 72px;
  font-weight: 600;
  margin-bottom:0px;
}
.sofia-h2{
  font-family: 'SofiaPro';
  font-size: 60px;
  line-height: 94px;
  font-weight: 700;
  margin-bottom:30px;
}
.sectionTitle{
  text-align: center;
  max-width: 1129px;
  margin: 0 auto;
  z-index: 9999;
  position: relative;
}
.circle{
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #FD6F21;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
.circle::before{
  content: '';
  display: block;
  width: 100%;
  height: 50%;
  margin: 5px;
  border-radius: 50%;
  background: #FD6F21;
}
.iconDD-Btn, .iconDD-Btn:active{
  padding: 0px;
  margin: 0px;
  background-color: transparent !important;
  border: 0px;
  font-size: inherit;
  transition: all ease-in-out .4s;
}
.themeEffect{
  transition: all ease-in-out .4s;
}
.iconDD-Btn:hover, .themeEffect:hover{
  color: #FC491E !important;
  cursor: pointer;
}
.iconDD-Btn::after{
  display: none;
}
.iconDD .dropdown-menu{
  padding: 0px;
  background: #222430;
  border-radius: 10px 0px 10px 10px;
  width: 218px;
  transform: translate(-200px, 34px) !important;
}
.iconDD .dropdown-item{
  font-family: 'outfit';
  font-size: 14px;
  line-height: 35px;
  font-weight: 400;
  border-radius: 10px 0px 10px 10px;
  color: #fff;
  transition: all ease-in-out .4s;
}
.barDD.iconDD .dropdown-menu {
  transform: translate(0px, 34px) !important;
}
.iconDD .dropdown-item:hover, .iconDD .dropdown-item:active, .iconDD .dropdown-item:focus{
  background: transparent;
  border: 0px;
  outline: none;
  color: #FD6F21;
}

/* TABLE */
.themeTable{
  background: #151721 0% 0% no-repeat padding-box;
  border-radius: 20px;
  overflow-x: auto;
}
.themeTable table{color: #FFFFFF;}
.themeTable table thead th p{
  font-family: 'Outfit';
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;
  opacity: 0.6;
  margin-bottom: 0px;
}
.themeTable table td p{
  font-family: 'Outfit';
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  margin-bottom: 5px;
  opacity: 1;
}
.themeTable table th, .themeTable table td{
  padding: 30px 0px 30px 30px;
  border-width: 0px;
  min-width: 145px;
}

.themeTable table tr:not(:last-child), .themeTable table thead {
  border-bottom: 1px solid #6468662e;
}

/* TAB */
.tabFlex{
  display: flex; 
  justify-content:space-between; 
  align-items: flex-end;
  border-bottom: 2px solid #6468662e;
} 
.tabFlex > ul {
  padding: 0px 40px;
}
.tabFlex .realtimeBox{
  padding-bottom: 28px ;
}
 .react-tabs__tab-list{
  border-bottom: 0px solid #6468662e;
  margin-bottom: 0px !important;
}
.tabFlex h4{
  font-family: 'outfit';
  font-size: 20px;
  line-height: 25px;
  font-weight: 500;
  opacity: 0.75;
}
/* .themeAnalytics .react-tabs__tab-list{padding: 0px 40px;} */
.tabFlex{padding: 0px 40px 0px 0px; margin-right: 40px;}
.themeAnalytics .react-tabs__tab-list li:not(:last-child){
  margin-right: 80px;
}
.themeAnalytics .react-tabs__tab-panel{padding:40px;}
.themeAnalytics .react-tabs__tab{
  position: relative;
  font-family: 'Outfit';
  font-size: 20px;
  line-height: 25px;
  font-weight: 500;
  border: 0px;
  padding-bottom: 18px;
  background: transparent;
  color: #fff;
  opacity: 0.6;
  margin-right: 10px;
  transition: all ease-in-out .4s;
}
.themeAnalytics .react-tabs__tab.react-tabs__tab--selected{
  color: #FD6F21;
  opacity: 1;
}
.themeAnalytics .react-tabs__tab:hover:before , .themeAnalytics .react-tabs__tab.react-tabs__tab--selected::before{
  transform: scaleX(1);
}
.themeAnalytics .react-tabs__tab::before {
  content: '';
  display: block;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4px;
  border-radius: 30px;
  position: absolute;
  background: #FD6F21;
  transform: scaleX(0);
  transition: all ease-in-out .4s;
}
.dashboard-sitePages p.md-P{
  font-family: 'Outfit';
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: #FFFFFF;
  opacity: 0.4;
}
.themeAnalytics .react-tabs__tab::after{
  display: none;
}
.checkTable .form-check-input[type=checkbox]{
  margin: 0px;
}
.form-check.checkTable{
  margin-bottom: 0px;
  min-height: auto;
  padding-left: 0;
}
/* GRAPhH CSS */
.graphItems ul {
  padding-left: 0px;
  margin-bottom: 0px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.graphItems ul  li p{
  font-size: 20px;
  line-height: 48px;
  font-weight: 400;
  opacity: 0.6;
  margin-bottom: 0px;
}
.graphItems ul  li:not(:last-child){
  margin-right: 80px;
}
.graphItems ul  li > div {
  display: flex;
  align-items: center;
}
.graphItems ul  li > div::before{
  content: '';
  display: block;
  height: 2px;
  width: 18px;
  border-radius: 2px;
  margin-right: 20px;
}
.graphItems ul  li > .yellow::before{
  background-color: #F9B52B;
}
.graphItems ul  li > .blue::before{
  background-color: #5490F2;
}
.graphItems ul  li > .orange::before{
  background-color: #FD6F21;
}
/* MEDIA QUERIES */
@media (max-width:1722px){
.customBox{ max-width: 1600px; }
}
@media (max-width:1600px){
.customBox{ max-width: 1500px; }
}
@media (max-width:1500px){
  .customBox{ max-width: 1400px; }
}
@media (max-width:1400px){
  .customBox{ max-width: 1300px; }
}
@media (max-width:1300px){
  .customBox{ max-width: 1200px; }
}
@media (max-width:1200px){
  .customBox{ max-width: 1100px; }
}
@media (max-width:1100px){
  .customBox{ max-width: 1000px; }
}
@media (max-width:1000px){
  .customBox{ max-width: 900px; }
}
@media (max-width:900px){
  .customBox{ max-width: 800px; }
}
@media (max-width:800px){
  .customBox{ max-width: 650px; }
}
@media (max-width:700px){
  .customBox{ max-width: 600px; }
}
@media (max-width:600px){
  .customBox{ max-width: 500px; }
}
@media (max-width:1699px) {
.iconDD .dropdown-menu{ border-radius: 8px 0px 8px 8px; width: auto; transform: translate(-150px, 34px) !important;}
.iconDD .dropdown-item{ font-size: 13px; line-height: 25px; border-radius: 8px 0px 8px 8px; padding: 8px 8px;}
.themeAnalytics .react-tabs__tab-list { padding: 0px 20px;}
/* TABLE */
.themeTable{ border-radius: 8px;}
.themeTable table thead th p, .themeTable table td p{ font-size: 14px; line-height: 15px;}
.themeTable table th, .themeTable table td{ padding: 25px 0px 25px 25px;}
.dashboard-sitePages p.md-P{font-size: 12px; line-height: 20px;}
.dashboard-sitePages .analyticDetailBox h4{margin-bottom: 3px;}
/* TAB */
.themeAnalytics .react-tabs__tab-list{padding: 0px 20px;}
.themeAnalytics .react-tabs__tab-list li:not(:last-child){margin-right: 50px;}
.themeAnalytics .react-tabs__tab-panel{padding:20px;}
.themeAnalytics .react-tabs__tab{ font-size: 17px; line-height: 20px; padding-bottom: 18px;}
.themeAnalytics .react-tabs__tab::before { height: 2px; border-radius: 20px;}
.link.orangeLink, .tabFlex h4{ font-size: 18px;  line-height: 22px; }
.tabFlex > ul { padding: 0px 20px;}
.tabFlex .realtimeBox { padding-bottom: 20px;}
.checkTable .form-check-input[type=checkbox]{font-size: 13px;}
.graphItems ul  li p{ font-size: 17px; line-height: 20px;}
.graphItems ul  li:not(:last-child){ margin-right: 40px;}
.graphItems ul  li > div::before{ height: 2px; width: 13px; border-radius: 1px; margin-right: 10px;}
}
@media (max-width:1520px) {
  section{ padding: 60px 0px 0px 0px;}
  a{ font-size: 18px; line-height: 22px; }
  .outfit-p{ font-size: 16px; line-height: 20px; }
  .sofia-p{ font-size: 18px; line-height: 24px;}
  .blueLink{ margin-bottom: 8px; font-size: 14px; line-height: 18px;}
  .theme-btn, button{ font-size: 14px; line-height: 18px; border-radius: 5px; padding: 10px 25px;}
  .orange-btn, .outline-btn{ margin-top: 50px;}
  .italicLink{ font-size: 18px; line-height: 22px;}
  h1{ font-size: 35px; line-height: 39px; margin-bottom: 18px;}
  h2, .sofia-h2{ font-size: 35px; line-height: 40px; margin-bottom: 18px; }
  .H2{ font-size: 25px; line-height: 29px; }
  .sectionTitle{max-width: 768px;}
  .circle{ width: 18px; height: 18px; }
  .circle::before{ margin: 4px; }
  .link{font-size: 12px; line-height: 18px;}
  .graphItems ul li > div::before { width: 10px;margin-right: 6px;}
  .graphItems ul li:not(:last-child) { margin-right: 17px;}
  .graphItems ul li p { font-size: 15px;line-height: 18px;}
}
@media (max-width:1399px) {
  ::-webkit-scrollbar, ::-webkit-scrollbar-thumb, ::-webkit-scrollbar-track { width: 5px;height: 5px;}
  section{ padding: 40px 0px 0px 0px;}
  .sectionTitle{max-width: 650px;}
  .orange-btn, .outline-btn {margin-top: 35px;}
  .theme-btn, button, .orange-btn, .outline-btn{border-width: 1px;}
  a, .italicLink{ font-size: 16px; line-height: 20px; }
  .sofia-p{ font-size: 16px; line-height: 24px;}
  .outfit-p{ font-size: 14px; line-height: 19px; }
  .circle { width: 15px; height: 15px;}
}
@media (max-width: 896px) {
  .iconDD .dropdown-menu {
    transform: translate(-6px, 42px) !important;
  }
}
@media (max-width:776px) {
.tabFlex{flex-direction: column-reverse; padding: 10px; margin: 0px;}
.tabFlex > ul, .themeAnalytics .react-tabs__tab-list{width: 100%;}
.tabFlex > ul li, .themeAnalytics .react-tabs__tab-list li{margin-bottom:10px;}
.tabFlex .realtimeBox{width: 100%; display: flex; justify-content: center;}
.themeAnalytics .react-tabs__tab{margin: 0px 10px 10px 10px;}
.videoImg{width: 90px; height: 90px;}
.videoImg img{width:100%; height: 100%; object-fit: cover;}
}
@media (max-width:567px) {
  section{ padding: 60px 0px 0px 0px;}
  a{ font-size: 16px; line-height: 20px; }
  .outfit-p{ font-size: 14px; line-height: 19px; }
  .sofia-p{ font-size: 16px; line-height: 22px;}
  .orange-btn, .outline-btn{ margin-top: 30px;}
  .italicLink{ font-size: 16px; line-height: 20px;}
  h1, h2,  .sofia-h2 { font-size: 30px; line-height: 34px; margin-bottom: 15px;}
  h1, h2 {line-height: 36px; }
  .H2{ font-size: 20px; line-height: 24px; }
}