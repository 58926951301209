.checkTable input, .checkTable .form-check-input[type=checkbox]{border: 1px solid #FFFFFF; border-radius: 6px; opacity: 0.5; background-color: transparent !important;}
.videoInfo{display: flex; }
.videoIcons{display: flex; align-items: center;}
.videoIcons{margin-top: 21px;}
.videoInfo .videoImg{width: 150px; height: 100px;margin-right: 20px;}
.videoInfo > img{ object-fit: cover;}
.themeTable table td .videoInfo p {font-weight: 600;}
.videoIcons > span:not(:last-child){margin-right: 30px;}
.analyticGraph{background: #151721; border-radius: 20px;}
.analyticsBar{display: flex; border-bottom: 1px solid #282C3C;}
.analyticsBar .analyticsBarBox{padding: 35px; text-align: center; width: 100%;}
.analyticsBar .analyticsBarBox:first-child{background-color: #282C3C; border-top-left-radius: 20px;}
.analyticsBar .analyticsBarBox:not(:last-child){border-right: 2px solid #282C3C;}
.analyticsBar .analyticsBarBox span{font-family: 'Outfit'; font-size: 16px; line-height: 20px; font-weight: 500;opacity: 0.4;}
.analyticsBar .analyticsBarBox h4{font-family: 'Outfit'; font-size: 30px; line-height: 38px; font-weight: 500;margin-bottom: 0px;}
.realtimeBox{padding-bottom: 70px; border-bottom: 1px solid #282C3C;}
.realtimeBox h5{font-family: 'Outfit'; font-size: 24px; line-height: 30px; font-weight: 500;margin-bottom: 6px;}
.realtimeBox p{font-weight: 500;line-height: 20px;}
.realtimeBox-btm{ display: flex; justify-content: space-between; padding: 20px 0px 5px 0px;}
.videoIcons{margin-top: 21px;}
.videoInfo .videoImg{width: 150px; height: 100px;margin-right: 20px;}
.videoInfo > img{ object-fit: cover;}
.videoIcons > .iconBtn{ padding: 0px; background: transparent; color: #fff; border: 0px; font-size: inherit;}
.videoIcons > span:not(:last-child) ,.videoIcons > .iconBtn:not(:last-child) {margin-right: 30px;}


@media (max-width:1699px) {
.checkTable input, .checkTable .form-check-input[type=checkbox]{border-radius: 4px; }
.videoIcons{margin-top: 21px;}
.videoInfo .videoImg{width: 120px; height: 90px;margin-right: 10px;}
.videoIcons > span:not(:last-child){margin-right: 30px;}
.analyticGraph{border-radius: 8px;}
.analyticsBar .analyticsBarBox{padding: 35px; text-align: center; width: 100%;}
.analyticsBar .analyticsBarBox:first-child{border-top-left-radius: 10px;}
.analyticsBar .analyticsBarBox span{font-size: 14px; line-height: 18px; }
.analyticsBar .analyticsBarBox h4{font-size: 24px; line-height: 28px; }
.realtimeBox{padding-bottom: 70px; border-bottom: 1px solid #282C3C;}
.realtimeBox h5{font-size: 18px; line-height: 24px; margin-bottom: 6px;}
.realtimeBox p{font-weight: 500;line-height: 20px;}
.realtimeBox-btm{ display: flex; justify-content: space-between; padding: 20px 0px 5px 0px;}
}

@media (max-width:776px) {
.analyticsBar{flex-wrap: wrap;border-bottom: 0px;}
.analyticsBar .analyticsBarBox{padding: 25px;}
.analyticsBar .analyticsBarBox:first-child { border-top-left-radius: 10px; border-top-right-radius: 10px;}
.analyticsBar .analyticsBarBox:not(:last-child) { border-right: 0px solid #282C3C;}
.analyticsBar .analyticsBarBox:not(:last-child){border-bottom: 1px solid #282C3C;}
}