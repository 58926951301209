.package-card{background: #393C48 ; border: 2px solid transparent; border-radius: 20px; padding:40px 40px 30px 40px; text-align: left; height: 100%;display: flex; flex-direction: column; justify-content: space-between; transition: all ease-in-out .4s;}
.package-card:hover {border-color: #FC491E;transform: scale(1.02); }
.package-card:hover .orange-btn{background-color: #FC491E; color: #fff;}
.packageCardHeader{margin-bottom: 0px; padding-left: 0px; list-style: none; display: flex;}
.packageCardHeader div:not(:last-child){margin-right: 60px;}
.package-card .packageH3{font-family: 'outfit'; font-size: 60px; line-height: 76px; font-weight: 700; color: #FD6F21; margin-bottom: 30px;}
.package-card .packageTitle{font-family: 'outfit'; font-size: 16px; line-height: 20px; font-weight: 400; color: #fff; opacity: 0.6; margin-bottom: 1px; text-transform: uppercase;}
.h3Sub{font-family: 'outfit'; font-size: 20px; line-height: 48px; font-weight: 400; color: #fff; text-transform: lowercase; margin-left: 5px;}
.package-ul{margin-bottom: 32px; padding-left: 0px; list-style: none;}
.package-ul li:not(:last-child){margin-bottom:10px;}
.package-ul li p{font-family: 'outfit'; font-size: 20px; line-height: 48px; font-weight: 400; margin-bottom:0px; color: #fff; margin-left: 15px;}
.package-ul li{display: flex; align-items: center;}
.package-ul li::before{content: ''; display: block; width: 9px; height: 1px; background-color: #fff;}

@media (max-width:1699px) {
.packageCardHeader div:not(:last-child){margin-right: 30px;}
.package-card .packageH3{ font-size: 45px; line-height: 49px; margin-bottom: 20px;}
.package-card .packageTitle{ font-size: 14px; line-height: 19px;}
.h3Sub{ font-size: 18px; line-height: 24px;  margin-left: 3px;}
.package-ul li p{ font-size: 18px; line-height: 24px; margin-left: 13px;}
}
@media (max-width:1399px) {
.package-card{ border-radius: 15px; padding:30px 30px 25px 30px;} 
.packageCardHeader div:not(:last-child){margin-right: 15px;}
.package-card .packageH3{ font-size: 35px; line-height: 39px; margin-bottom: 15px;}
.package-card .packageTitle{ font-size: 10px; line-height: 14px;}
.h3Sub{ font-size: 16px; line-height: 20px;  margin-left: 3px;}
.package-ul li p{ font-size: 14px; line-height: 18px; margin-left: 10px;}
.package-ul li::before { width: 4px;}
}