.theme-form{background-image: url('./../../../assets/images/FormsBG.png'); background-repeat: no-repeat; background-size: cover;     height: 100vh; min-height: calc(100vh - 150px); width:100%; padding: 0px 0px 50px 0px;overflow-y: scroll;}
.form-overlay{background-color: rgb(13 15 23 / 90%); height: 100%; width: 100%; position: fixed; top: 0px; }
.formContainer{padding: 0px 40px; min-height: 100vh; display: flex; flex-direction: column; justify-content: center;}
.formLogo{padding: 30px 0px 64px 0px;}
.formLogo > a {display: inline-block}
.formLogo img{width: 121px;}
.formGroup{margin-bottom: 20px;}
.biiview-form{position: relative; z-index: 999;}
.formStyle{min-width: 600px; background: #1B1D27; border-radius: 30px; padding: 50px 60px;  text-align: center; margin-bottom: 40px;}
.wrapForm{height: 100%; margin:auto;}
.form-label, .form-control{font-family: 'outfit'; font-size: 16px; line-height: 20px; font-weight: 400; margin-bottom: 10px;text-align: left;width: 100%;}
.form-control { background: #393C48 !important; border-radius: 10px; border: 1px solid transparent; height: 60px; color: #fff;}
.form-control:focus { background: #393C48 !important; border: 1px solid transparent; color: #fff;}
.inputDiv{ background: #393C48 !important;border-radius: 10px; border: 1px solid transparent; height: 62px; color: #fff; display: flex; align-items: center;padding-right: 20px;}
.inputDiv input{background: transparent !important;margin: 0px; color: #fff; border: 1px solid transparent;}
.inputDiv svg{color: #FD6F21;}
.inputDiv:hover{ outline: #FD6F2180; border-color: #FD6F2180;}
.inputDiv input.form-control:focus, .inputDiv input.form-control:focus-visible {box-shadow: none; outline: transparent; border-color: transparent; border: 0px;color: #fff;}
.form-control:focus, .form-control:focus-visible {box-shadow: none; outline: #FD6F2180; border-color: #FD6F2180}
.formStyle h2{font-family: 'outfit'; font-size: 30px; line-height: 72px; font-weight: 600; margin-bottom: 10px;}
.theme-form p{font-family: 'outfit'; font-size: 16px; line-height: 20px; font-weight: 400; color: #D0BBB6;}
.theme-form .outer-p{color: #fff;}
.formHeader{margin-bottom: 30px; padding-bottom: 30px; border-bottom: 1px solid #393C48;}
.formStyle .orange-btn{margin-top: 40px;}
.theme-capcha {padding-top: 10px; display: flex; flex-direction: column; align-items: center;}
.end-mb{margin-bottom: 60px;}
.forgot{max-width: 480px;}
/* PACKAGE */
.formStyle.package{max-width: 1742px;}

/* MEDIA QUERY */

@media (max-width: 1699px) {
.formContainer{padding: 0px 35px;}
.formLogo{padding: 25px 0px 40px 0px;}
.formGroup{margin-bottom: 20px;}
.formStyle{min-width: 450px; border-radius: 15px; padding: 28px 30px; margin-bottom: 30px;}
.form-label, .form-control{font-size: 12px; line-height: 18px; margin-bottom: 8px;}
.form-control {border-radius: 5px;height: 40px; }
.inputDiv{height: 42px;padding-right: 10px;border-radius: 5px;}
.formStyle h2{font-size: 25px; line-height: 30px; margin-bottom: 8px;}
.theme-form p{font-size: 12px; line-height: 18px;}
.formHeader{margin-bottom: 30px;}
.formStyle .orange-btn{margin-top: 20px;}
.end-mb{margin-bottom: 30px;}
.theme-capcha {padding-top: 8px;}

}
@media (max-width: 1399px) {
.formStyle{margin-bottom: 15px;}
.formHeader{padding-bottom: 20px;}
.formHeader { margin-bottom: 20px;}
.formLogo{padding: 15px 0px 20px 0px;}
.forgot{max-width: 380px;}
}
@media (max-width: 767px) {
.wrapForm{width: 100%;}
.formStyle{min-width: 80%; border-radius: 15px; padding: 28px 30px; }
.formContainer{padding: 0px 25px;}
.formLogo{padding: 15px 0px 20px 0px;}
 }
