.dashboard-sitePages .analyticDetailBox h4{margin-bottom: 6px;}
.tabBox > div{margin-bottom: 40px;}
.analyticGraph{margin-bottom: 30px;}
/* analyticDetailBox MARGN BOTTOM */ 
.analyticDetailBox .audiance {margin-bottom: 70px;}
.analyticDetailBox .likes {margin-bottom: 40px;}
/*  */
.boxUl{padding-left: 0px; margin-bottom: 19px;list-style: none;}
.boxUl li:not(:last-child){margin-bottom: 30px;}
.dashboard-sitePages .boxUl p.md-P{opacity: 0.8; line-height: 20px; margin-bottom: 1px;}
.analyticPer{display: flex; justify-content: space-between;}
.dotDiv{width: 45px;}
.orangeDot{display:block;width: 10px; height: 10px; border-radius: 50%; background-color: #FD6F21; }
.greyDot{display:block;width: 10px; height: 10px; border-radius: 50%; background-color: #3B3F57;}
.badgeFlex {display: flex;  flex-wrap: wrap; margin: 20px 0px;}
.badgeFlex .badge{margin-right: 12px; margin-bottom: 5px;}
.bg-secondary{ font-family: 'Outfit'; font-size: 14px; line-height: 18px; font-weight: 500; opacity: 1; background-color: #282C3C !important; color: #5D637B; height: 42px; display: flex; align-items: center; justify-content: center;}
.analyticResearch{text-align: center; margin: 0 auto;}
.Tab-sofia-p{ font-family: 'SofiaPro'; font-size: 20px; line-height: 26px; opacity: 0.4;}
.topRes{margin: auto auto 60px auto; max-width: 600px;}
.researchForm{background: #393C48; border-radius: 10px; padding: 30px; max-width: 600px; margin: 0 auto; display: flex; align-items: center; justify-content: space-between;}
.researchForm .researchIcon{margin-right: 20px;}
.researchForm .researchIcon svg{color: #FC491E; font-size: 20px;}
.researchForm input{width:100%; background-color: transparent; border: 0px; color: #fff; height: 100%;}
.researchForm input:focus-visible{outline: none; border: 0px;}

@media (max-width:1699px) {
.tabBox > div{margin-bottom: 20px;}
.orangeDot, .greyDot{ width: 6px; height: 6px; }
/* analyticDetailBox MARGN BOTTOM */
.analyticDetailBox .audiance {margin-bottom: 50px;}
.analyticDetailBox .likes {margin-bottom: 20px;}
/*  */
.boxUl{ margin-bottom: 9px;}
.boxUl li:not(:last-child){margin-bottom: 15px;}
.badgeFlex { margin: 10px 0px;}
.badgeFlex .badge{margin-right: 8px;}
.bg-secondary{ font-size: 12px; line-height: 16px; height: 25px;}
.Tab-sofia-p{ font-size: 18px; line-height: 22px; }
.topRes{margin: auto auto 40px auto; max-width: 400px;}
.researchForm{ border-radius: 8px; padding: 20px; max-width: 400px;}
.researchForm .researchIcon{margin-right: 15px;}
.researchForm .researchIcon svg{ font-size: 15px;}
}