.wrapFooter{ position: relative; overflow: hidden;}
.footer{position: relative; padding: 70px 36px 0px 36px; margin-top: 120px; background: #1F212F;}
.wrapFooter .bar{position: absolute; bottom: 0px; right: 8%; width: 180px;height: 84.9%; transform: skew(46deg); overflow: hidden; background: transparent linear-gradient(174deg, #1f212f78 0%, #FD6F21 100%) 0% 0% no-repeat padding-box; z-index: 99; opacity: 0.65;}
.footerTop{text-align: center;}
.footerLogo{margin-bottom: 56px;}
.socialIcon{margin-bottom: 80px;}
.footerLink{margin-bottom: 40px;}
.socialIcon ul, .footerLink ul{margin-left: 0px; list-style: none; display: flex; justify-content: center; align-items: baseline;}
.socialIcon ul li a{width: 56px; height: 56px; box-shadow: 0px 2px 20px #0000001A; border: 1px solid #464646; border-radius: 50%; display: flex; align-items: center; justify-content: center; transition: all ease-in-out .4s;} 
.socialIcon ul li:not(:last-child){margin-right: 55px;}
.socialIcon ul li a svg{color: #fff; font-size: 18px; transition: all ease-in-out .3s;}
.socialIcon ul li a:hover{border: 2px solid #FC491E; }
.socialIcon ul li a:hover svg{   transform: scale(1.4);}
.footerLink ul li:not(:last-child){margin-right: 60px;}
.footerLink ul li a{ position: relative; z-index: 999; font-size: 20px; line-height: 31px; font-weight: 600; color: #fff; text-transform: capitalize; text-decoration: none; opacity: 1; transition: all ease-in-out .3s;}
.footerLink ul li a:hover{opacity: 1;}
.footerBtm{position: relative; border-top: 3px dashed #464646; padding: 30px 36px 40px 36px; text-align: center;}
.copyright{font-size: 18px; line-height: 31px; font-weight: 300;margin-bottom: 0px;}
.footerBtm .circle { position: absolute; top: -15px; right: 0; left: 0; margin: auto;}
/* MEDIA QUERY */
@media (max-width: 1699px) {
.footer{padding: 25px 24px 0px 24px;}
.footerLogo{margin-bottom: 35px;}
.socialIcon{margin-bottom: 80px;}
.footerLink{margin-bottom: 15px;}
.footerLogo img{width: 150px;}
.socialIcon ul li a{width: 50px; height: 50px;} 
.socialIcon ul li a:hover svg {transform: scale(1.3)}
.socialIcon ul li:not(:last-child){margin-right: 20px;}
.footerLink ul li a{ font-size: 18px; line-height: 24px;}
.footerLink ul li:not(:last-child){margin-right: 80px;}
.footerBtm{border-top: 2px dashed #464646; padding: 15px 24px 19px 24px;}
.copyright{font-size: 14px; line-height: 18px; }
.footerBtm .circle { top: -11px;}
}
@media (max-width:1399px) {
.footer {margin-top: 80px;}
.footerBtm .circle { top: -9px;}
.wrapFooter .bar {width: 140px;}
}
@media (max-width:992px) {
.socialIcon ul, .footerLink ul{flex-wrap: wrap;}
.socialIcon {margin-bottom: 50px;}
.footerLink ul li:not(:last-child) {margin-right: 40px;}
}
@media (max-width:667px) {
.socialIcon ul, .footerLink ul{flex-wrap: wrap;}
.footerLink ul li, .footerLink ul li:not(:last-child){ margin: auto 40px 15px 40px;}
.socialIcon ul li a{margin-bottom: 15px;}
.socialIcon {margin-bottom: 30px;}
}