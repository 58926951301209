.works .sectionTitle{margin-bottom: 160px; position: relative; z-index: 999;}
.works{position: relative;padding-bottom: 252px;}
.works .bar{position: absolute; top: 80px; right: 15%; left: 0;  width: 180px;height: 40%;background: #FC491E;transform: skew(-42deg); margin: auto; overflow: hidden; background: transparent linear-gradient(174deg, #FD6F21 0%, #0D0F17 100%) 0% 0% no-repeat padding-box; z-index: 99;opacity: 0.65;}
.lineH2{position: relative;}
.wrapLine{ position: absolute; right: 0px; top: 25px; bottom: 0; margin-left: auto; height: 0;}
.line{display: block; width: 350px; border-bottom: 1px dashed #fff; height: 0px; opacity: 0.5; margin-right: 216px;}
.line1{content:''; display:block;width: 250px; border-bottom: 1px dashed #fff;height: 1px; transform: rotate(45deg); position: absolute; top: 90px; right: 0;opacity: 0.5;}
.lineH2 .circle{opacity: 1; opacity: 1; position: absolute; right: -10px; bottom: -15px; border: 1px solid #fff;}
.style360 {position: relative; width: 767px; margin: 0 auto; z-index: 99;}
.pieText h3{font-family: 'outfit'; font-size: 20px; line-height: 25px; font-weight: 700; color: #fff;margin-bottom: 10px;}
.pieText .pie-P{font-family: 'outfit'; font-size: 16px; line-height: 22px; font-weight: 400; margin-bottom: 0; opacity: 0.4;}
.shape-hover, .shape2, .shape3{position: relative;}
.shape-hover .backImg{ position: absolute; right: 0; left: 0; transition: all ease-in-out .4s;}
.shape1{column-gap: 30px;}
.shape1, .shape2 .wrap-style360img:nth-child(1), .shape2 .wrap-style360img:nth-child(2), .shape3 .wrap-style360img:nth-child(1), .shape3 .wrap-style360img:nth-child(2){z-index: 9999;}
.shape2 .wrap-style360img:nth-child(1){position: absolute; top:-165px; left:-72px;}
.shape2 .wrap-style360img:nth-child(2){position: absolute; top:-165px; right:-72px;}
.shape3 .wrap-style360img:nth-child(1){position: absolute; top:165px; left:-60px;}
.shape3 .wrap-style360img:nth-child(2){position: absolute; top:165px; right:-60px;}
.shape4{margin-top: 255px;}
.pie-circle, .pie-circle1, .pie-circle2, .pie-circle3, .pie-circle4, .pie-circle5{ display: block; position: absolute; right: 0; left: 0; top: 0; bottom: 0; margin: auto; border-radius: 50%;}
.pie-circle1{ width: 80px; height: 80px; border:1px solid #FD6F21; opacity: 0.3;}
.pie-circle2{ width: 100px; height: 100px; border:1px solid #FD6F21; opacity: 0.2;}
.pie-circle3{ width: 120px; height: 120px; border:1px solid #fd6f2196; opacity: 0.2;}
.pie-circle4{ width: 130px; height: 130px; border-radius: 50%; border:1px solid #fd6f2196; opacity: 0.1;}
.pie-circle5{ width: 50px; height: 50px; background-color: #FD6F21; opacity: 0.9;}
.dark{ width: 300px; height: 300px; background-color: #0D0F17; border-radius: 50%; display: flex; align-items: center; justify-content: center; position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto;}
.display .bii{display: none;}
.display{position: relative;transition: all ease-in-out .3s;}
.hoverImg{  margin: 0; position: absolute; top: 100%; left: 100%; -ms-transform: translate(-50%, -53%); transform: translate(-50%, -50%);transition: all ease-in-out 0s;visibility: hidden; opacity: 0; z-index: 9999;}
.wrap-style360img:hover .hoverImg{ margin: 0; position: absolute; top: 0; left: 40%; -ms-transform: translate(-50%, -53%); transform: translate(-50%, -50%);transition: all ease-in-out .4s;visibility: visible; opacity: 1;}
.pieText{width: 210px;}
.pieText{position: absolute; top: 25%; left: 25%; margin: auto; bottom: 0;}
.wrap-style360img:hover .display .bii{display: block;}
.wrap-style360img:hover .hide{display: none;}
/* shape1 */
.shape1 .wrap-style360img:nth-child(1) .pieText{ top: 30%; left: 35%;}
.shape1 .wrap-style360img:nth-child(2) .pieText{ top: 30%; left: 15%;}
.shape1 .wrap-style360img:nth-child(1) .hoverImg{left: 40%; top: 9px;}
.shape1 .wrap-style360img:nth-child(1):hover .hoverImg{top: -6px;}
.shape1 .wrap-style360img:nth-child(2) .hoverImg{left: 50%; transform: translate(-45%, -50%); top: 9px;}
.shape1 .wrap-style360img:nth-child(2):hover .hoverImg{left: 50%; transform: translate(-45%, -50%); top: -10px;}
/* shape2 */
.shape2 .wrap-style360img:nth-child(1) .pieText{ top: 33%; left: 13%;}
.shape2 .wrap-style360img:nth-child(2) .pieText{ top: 40%;}
.shape2 .wrap-style360img:nth-child(1) .hoverImg { left: -42%; transform: translate(0%, 0%); top: 21px; bottom: 0; right: 100%;}
.shape2 .wrap-style360img:nth-child(1):hover .hoverImg{left: -48%;}

.shape2 .wrap-style360img:nth-child(2) .hoverImg{right: -42%; transform: translate(0%, 0%); top: 21px; bottom: 0; left: 80%;} 
.shape2 .wrap-style360img:nth-child(2):hover .hoverImg { left: 90%;}
/* shape3 */
.shape3 .wrap-style360img:nth-child(1) .hoverImg{top: 20%; left: 0; transform: translate(0%, 0%);} 
.shape3 .wrap-style360img:nth-child(1):hover .hoverImg{ top: 30%; transform: translate(-29%, 0%);}
.shape3 .wrap-style360img:nth-child(2) .hoverImg{top: 25%; left: 45%; transform: translate(0%, 0%);} 
.shape3 .wrap-style360img:nth-child(2):hover .hoverImg {top: 27%; left: 48%; transform: translate(0%, 0%);}
/* shape4 */
.shape4 .wrap-style360img .pieText{ top: 37%; left: 22%;}
.shape4 .wrap-style360img .hoverImg{top: 90%; bottom: 0; left: 47%; transform: translate(-50%, 2%);} 
.shape4 .wrap-style360img:hover .hoverImg{ top: 100%; bottom: 0; left: 47%; transform: translate(-50%, 2%);}




/* MEDIA QUERY */
@media (max-width:1600px) {
.works{padding-bottom: 200px;}
.line{max-width: 280px; margin-right: 152px;}
.line1{max-width: 180px; top: 63px;}
}
@media (max-width: 1399px) {
.works{padding-bottom: 150px;}
.line{ max-width: 200px;margin-right: 138px;}
.line1{ max-width: 160px; top: 57px; }
.lineH2 .circle{ right: -15px; bottom: -9px;}
.hoverImg {width: 200px;}
.pieText { width: 140px; top: 19%; left: 29%;}
.pieText h3{font-size: 17px; line-height: 22px; margin-bottom:3px;}
.pieText .pie-P{font-size: 12px; line-height: 17px; }
.pie-circle1{ width: 50px; height: 50px; }
.pie-circle2{ width: 70px; height: 70px; }
.pie-circle3{ width: 80px; height: 80px; }
.pie-circle4{ width: 100px; height: 100px; }
.pie-circle5{ width: 35px; height: 35px; }
.dark{width: 150px; height:150px;}
.shape1 { column-gap: 13px; margin-bottom: 13px;}
.display .bii , .hide img {width: 240px;}
.display img.bii2, .hide img.bii2{width: 270px;}
.display img.bii3, .hide img.bii3{width: 275px;}
.shape2 .wrap-style360img:nth-child(1){top: -143px; left: 62px;}
.shape2 .wrap-style360img:nth-child(2) { top: -143px; right: 62px;}
.shape3 .wrap-style360img:nth-child(1){ top:81px; left:65px;}
.shape3 .wrap-style360img:nth-child(2){ top:81px; right:65px;}
.shape4 { margin-top: 150px;}
.workReltive{padding-top: 80px;}
.works .bar {width: 120px; right: 21%;}
.works .sectionTitle {  margin-bottom: 90px;}
.labelImg{width: 150px;}
.shape2 .wrap-style360img:nth-child(1):hover .hoverImg { left: -54%; top: 23px;}
.shape3 .wrap-style360img:nth-child(2):hover .hoverImg {top: 32%;left: 52%;}
.shape2 .wrap-style360img:nth-child(2) .hoverImg{width: 160px;}
}
@media (max-width:900px) {
.pieText{width: 110px;}
.pieText h3{font-size: 12px; line-height: 14px; }
.pieText .pie-P { font-size: 8px; line-height: 12px;}
.shape1 .wrap-style360img:nth-child(1) .pieText { top: 25%; left: 32%;}
.style360 {width: 80%;}
.pie-circle1{ width: 35px; height: 35px; }
.pie-circle2{ width: 45px; height: 45px; }
.pie-circle3{ width: 50px; height: 50px; }
.pie-circle4{ width: 55px; height: 55px; }
.pie-circle5{ width: 25px; height: 25px; }
.dark{width: 90px; height:90px;}
.labelImg{width:120px;}
/* .hoverImg , .labelImg{width:90px;} */
.display .bii, .hide img {width: 160px;}
.display img.bii2, .hide img.bii2 { width: 170px;}
.display img.bii3, .hide img.bii3 {width: 180px;}
.shape1 { column-gap: 9px; margin-bottom: 4px;}
.shape2 .wrap-style360img:nth-child(1){top: -89px; left: 94px;}
.shape2 .wrap-style360img:nth-child(2) { top: -89px; right: 94px;}
.shape3 .wrap-style360img:nth-child(1){ top:63px; left:104px;}
.shape3 .wrap-style360img:nth-child(2){ top:63px; right:104px;}
.shape4 { margin-top: 103px;}
.shape2 .wrap-style360img:nth-child(1) .hoverImg {top: -3px;}
.shape2 .wrap-style360img:nth-child(1):hover .hoverImg { left: -63%; top: -3px;}
.shape2 .wrap-style360img:nth-child(2) .hoverImg{width: 100px;}
.shape3 .wrap-style360img:nth-child(1) .hoverImg { width: 120px;}
.shape3 .wrap-style360img:nth-child(2) .hoverImg { width: 130px;}
.shape3 .wrap-style360img:nth-child(2):hover .hoverImg { top: 28%;}
}
@media (max-width:992px) {
.line{max-width: 150px;margin-right: 100px;}
.line1{max-width: 120px;top: 42px;}
}
@media (max-width:800px) {
.pieText{width: 110px;}
.pieText h3{font-size: 12px; line-height: 14px; }
.pieText .pie-P { font-size: 8px; line-height: 12px;}
.shape1 .wrap-style360img:nth-child(1) .pieText { top: 25%; left: 32%;}
.style360 {width: 80%;}
.pie-circle1{ width: 35px; height: 35px; }
.pie-circle2{ width: 45px; height: 45px; }
.pie-circle3{ width: 50px; height: 50px; }
.pie-circle4{ width: 55px; height: 55px; }
.pie-circle5{ width: 25px; height: 25px; }
.dark{width: 90px; height:90px;}
.hoverImg {width:90px;}
.display .bii, .hide img {width: 160px;}
.display img.bii2, .hide img.bii2 { width: 170px;}
.display img.bii3, .hide img.bii3 {width: 180px;}
.shape1 { column-gap: 9px; margin-bottom: 6px;}
.shape1 .wrap-style360img:nth-child(1) .hoverImg { top: 0px; width: 145px;}
.shape1 .wrap-style360img:nth-child(1):hover .hoverImg { top: -3px; width: 145px;}
.shape1 .wrap-style360img:nth-child(2) .hoverImg{ width: 145px;}
.shape2 .wrap-style360img:nth-child(1){top: -90px; left: 38px;}
.shape2 .wrap-style360img:nth-child(1) .hoverImg { left: -36%; top: 19px;}
.shape2 .wrap-style360img:nth-child(1):hover .hoverImg { left: -48%; top: 19px;}
.shape2 .wrap-style360img:nth-child(2) { top: -90px; right: 38px;}
.shape3 .wrap-style360img:nth-child(1){ top:64px; left:45px;}
.shape3 .wrap-style360img:nth-child(2){ top:64px; right:45px;}

.shape4 { margin-top: 103px;}

 }
@media (max-width:700px) {
.shape1 { column-gap: 6px; margin-bottom: 5px;}
.shape2 .wrap-style360img:nth-child(1){top: -90px; left: 21px;}
.shape2 .wrap-style360img:nth-child(2) { top: -90px; right: 21px;}
.shape3 .wrap-style360img:nth-child(1){ top:59px; left:29px;}
.shape3 .wrap-style360img:nth-child(2){ top:59px; right:29px;}
.shape4 { margin-top: 99px;}
}
@media (max-width:767px) {
.wrapLine {display: none;}
.works { padding-bottom: 100px;}
}
@media (max-width:600px) {
.shape1 {column-gap: 8px; margin-bottom: 7px;}
.display .bii, .hide img { width: 155px;}
.display img.bii3, .hide img.bii3 { width: 177px;}
.shape2 .wrap-style360img:nth-child(1){top: -90px; left: -18px;}
.shape2 .wrap-style360img:nth-child(2) { top: -90px; right: -18px;}
.shape3 .wrap-style360img:nth-child(1){ top:54px; left:-12px;}
.shape3 .wrap-style360img:nth-child(2){ top:54px; right:-12px;}
.shape4 { margin-top: 94px;}
}
@media (max-width:476px) {
.shape1 { column-gap: 10px;}
.display .bii, .hide img { width: 100px;}
.display img.bii2, .hide img.bii2, .display img.bii3, .hide img.bii3 { width: 110px;}
.shape2 .wrap-style360img:nth-child(1){top: -55px; left: 33px;}
.shape2 .wrap-style360img:nth-child(2) { top: -55px; right: 33px;}
.shape3 .wrap-style360img:nth-child(1){ top:45px; left:39px;}
.shape3 .wrap-style360img:nth-child(2){ top:45px; right:39px;}
.shape4 {margin-top: 74px;}
}