/* modal width */
.settingModel .modal-dialog{max-width: 1160px; border-radius: 20px;}
.medium-Model .modal-dialog{max-width: 1160px;}
.uploadvideo .modal-dialog{max-width: 900px;}
.settingModel.confirmModel .modal-dialog{max-width: 600px;}
.narrowModel .modal-dialog{max-width: 710px;}
.fixedModal .modal-content{position: relative; overflow: hidden;}
.modelTabs .nav-pills{position: absolute;}
.videoModal{height: 800px; overflow-y: auto; overflow-x: hidden;padding-right: 5px;}
/*  */
.settingModel .modelTabs::-webkit-scrollbar { width: 8px; height: 8px; border-radius: 3px; margin-bottom: 10px;}
.settingModel .modelTabs::-webkit-scrollbar-track , .settingModel .modelTabs::-webkit-scrollbar-thumb{ background-color: #FD6F21; margin-bottom: 10px; border-radius: 3px;}
.modal .theme-btn, .modal button{padding: 25px 40px;}
.modal-content{background-color: #1D1F2C; padding: 30px; border-radius: 30px;}
.modalBox{padding: 30px 0px;}
.modal-body{padding: 145px 0px;}
.modal-header{padding: 0px 0px 30px 0px; border-bottom: 2px solid #6468662e;}
.modal-footer{padding: 21px 20px 20px 0px; border-top: 1px solid #6468662e;}
.modal-title, .modal-H4{font-family: 'outfit'; font-size: 24px; line-height: 30px; font-weight:500; color: #FFFFFF;opacity: 1; margin-bottom: 16px;}
.modal p{font-family: 'outfit'; font-size: 16px; line-height: 20px; font-weight:400;}
.modal .modalLightP{font-family: 'SofiaPro'; font-size: 20px; line-height: 26px; font-weight:300;}
.btn-close{opacity:1; filter: brightness(0) invert(1);}
.modal button{margin: top 40px;}
.uploadBtn{display: flex; justify-content: center;  align-items: center; width:150px; height:150px; border-radius: 50%; background-color: #303341; margin: auto auto 40px auto;}
.uploadBtn svg{font-size: 50px;}
.check-H4{font-weight: 400 !important; opacity: 0.99 !important;}
.fileGroup{margin-bottom: 30px;}
.fileGroup > div{margin-bottom: 12px;}
.fileGroup .fileLabel{margin-bottom: 10px;}
.fileGroup .fileBlock, .fileGroupList textarea{background: #393C48; border-radius: 10px; padding: 17px 20px; height: 84px; }
textarea:focus-visible{outline: none;}
.fileGroup .fileBlock p{opacity: 0.75;}
.fileGroupList textarea{font-family: 'outfit'; font-size: 16px; line-height: 20px; font-weight:400; color: #FFFFFF; overflow-y: auto; border: 0px; opacity: 1;}
textarea:-moz-placeholder, /* Firefox 18- */
textarea::-moz-placeholder, /* Firefox 19+ */
textarea:-ms-input-placeholder, /* IE 10+ */
textarea::-webkit-input-placeholder, /* Webkit based */
textarea::placeholder { /* Modern browsers */ color: #0bf; opacity: 0.75;  }
.thumbnail p{opacity: 0.4;}
.thumbnailImg {display: flex;flex-wrap: wrap;}
.thumbnailImg .videoImg:not(:last-child) {margin-right: 12px;}
.thumbnailImg .videoImg{background: #393C48 ;width: 143px; height: 100px;border-radius: 10px; margin-bottom: 10px; border: 2px solid transparent; transition: all ease-in-out .3s;}
.thumbnailImg .videoImg:hover{border: 2px solid #FD6F21;}
.thumbnailImg .videoImg img {object-fit: cover;height: 100%; width: 100%;border-radius: 10px;}
/* MODAL TBS */
.settingModel .modelTabs{ height: 750px; min-height: 100%; overflow-y: auto; overflow-x: hidden;}
.modelTabs .nav-pills{background: #151721; min-width: 250px; border-bottom-left-radius: 20px;}
.nav-pills .nav-link{font-family: 'outfit'; font-size: 16px; line-height: 20px; font-weight:500; color: #FFFFFF;  padding: 25px 0px 25px 40px;border-radius: 0px; color: #fff;opacity: 1;}
.modelTabs .nav-pills .nav-link.active, .modelTabs .nav-pills .nav-link:hover{background:rgb(48 51 65 / 50%); border-radius: 0px;}

.settingModel .modal-content{padding:0px ;}
.settingModel .modal-header{padding: 30px;}
.settingModel .modal-body{padding: 40px 40px 10px 40px}
.settingModel .tab-content{padding: 40px 40px 20px 40px; border-radius: 20px; height: 100%;}
.tab-content>.active, .modelTabs .nav-pills, .settingModel .modelTabs > .row{height: 100% ;}
.settingModel h4{font-family: 'outfit'; font-size: 20px; line-height: 25px; font-weight:500; color: #FFFFFF;opacity: 1; margin-bottom: 16px;}
.modalSelect{max-width: 425px; height: 80px;background: #393C48; border-radius: 10px; display: flex; align-items: center; position: relative;}
.modalSelect select{font-family: 'outfit'; font-size: 16px; line-height: 20px; font-weight:400; color: #FFFFFF; opacity: 0.75; background-color: transparent; width: 100%; height: 100%; border:0px; position: absolute; left: 0; right: 0; top:0; bottom:0; background-image: none;}
.modalSelect span{ margin-right: 30px; margin-left: auto;}
.settingFlex{ display: flex; flex-direction: column; justify-content: space-between; height: 100%;}
/* TAB */
.innerTabs p{opacity: 0.4;}
.innerTabs  .react-tabs__tab-list{position: relative; border-bottom: 0px solid #6468662e; margin-bottom: 0px !important;}
.innerTabs  .react-tabs__tab-list::after{content:''; border-bottom: 1px solid #6468662e; width: 100%; position: absolute; border-bottom: 2px solid #6468662e; width: 107%; position: absolute; bottom: -2px; right: 0; left: -40px; margin: auto;}
.innerTabs .react-tabs__tab-list li:not(:last-child){ margin-right: 67px;}
.innerTabs .react-tabs__tab-panel{ padding:40px 0px;}
/* .innerTabs .react-tabs__tab-panel{padding-bottom: 0px !important;} */
.innerTabs .react-tabs__tab{ position: relative; font-family: 'Outfit'; font-size: 16px;line-height: 20px; font-weight: 500; border: 0px; padding-bottom: 10px; background: transparent; color: #fff; opacity: 1; transition: all ease-in-out .4s;opacity: 0.6; }
.innerTabs .react-tabs__tab.react-tabs__tab--selected{ color: #FD6F21; opacity: 1;}
.innerTabs .react-tabs__tab:hover:before , .innerTabs .react-tabs__tab.react-tabs__tab--selected::before{ transform: scaleX(1);}
.innerTabs .react-tabs__tab::before { content: ''; display: block; left: 0; bottom: 0; width: 100%; height: 4px; border-radius: 30px; position: absolute; background: #FD6F21; transform: scaleX(0); transition: all ease-in-out .4s;}
.innerTabs .react-tabs__tab::after{display: none;}
.innerTabs .react-tabs, .innerTabs .react-tabs__tab-panel{height: 100%;}
.innerTabs .general{margin-bottom:30px;}
.innerTab-input-group{background: #393C48; border-radius: 10px; height: 80px;}
.innerTab-input-group input{font-size: 16px;line-height: 20px; font-weight: 400; height: 100%; width:100%; background: #393C48 !important; opacity: 0.75; color: #fff; border-color: #393C48;}
.innerTab-input-group input.form-control:focus{opacity: 0.75; color: #fff; border-color: #393C48; outline: none;}
.advanceSetting .general{margin-bottom: 24px;}
.laws > div{margin-bottom: 32px;}
.laws h5 {font-size: 18px;line-height: 23px; font-weight: 400;}
.laws ul{padding-left: 0px; margin-bottom: 30px; list-style: none;} 
.laws ul li{display: flex; align-items: center;}
.laws ul li:not(:last-child){margin-bottom: 15px;}
.laws ul li::before{content:''; width: 21px; height: 21px; border-radius: 50%; border:2px solid#83848B; background-color: transparent; margin-right: 15px;}
.laws ul li p{margin-bottom: 0px;}
.accountBtn{font-size: 18px;line-height: 20px; font-weight: 700; color: #FD6F21;}
.accountBtn:hover{ color: #FD6F21;}
.accountBtn.link::before{background: #FD6F21;}
.checkTable.modalCheck input, .checkTable.modalCheck .form-check-input[type=checkbox]{border-radius: 4px;}
.opacity-40 { opacity: 0.4 ;}
.max-95{max-width: 95%;}
/* Modal Table */ 
.modalTable{ background: transparent; border-radius: 0px; overflow-x: auto;}
.modalTable table{color: #FFFFFF;}
.modalTable table thead th p{ text-transform: capitalize; opacity: 0.8; }
.userName{ font-family: 'Outfit'; font-size: 18px; line-height: 23px; font-weight: 500; margin-bottom: 7px; opacity: 1;}
.roleProfile{display: flex; align-items: center;}
.roleProfile .roleImg{ height: 50px; width: 50px; border-radius: 50%; margin-right: 20px;}
.roleProfile .roleImg img{object-fit: cover; width: 100%; height:100%;}
.modalTable table td p.role{ text-transform: uppercase; opacity: 0.4;}
.modalTable table th, .modalTable table td{ padding: 30px 0px 0px 0px; border-width: 0px; min-width: 145px;}
.modalTable table tr:not(:last-child), .modalTable table thead { border-bottom: 1px solid #6468662e;}
.sideTab{width: 24%; z-index: 9;}
.faqTabs .accordion-header, .faqTabs .accordion-item, .faqTabs .accordion-button{background-color: #393C48; border-radius: 10px 10px 10px 10px !important; margin-bottom: 20px; box-shadow: none; border: 0px; align-items: flex-start; opacity: 1; color: #fff;}
.faqTabs .accordion-header, .faqTabs .accordion-button{margin-bottom: 0px !important;}
.faqTabs .accordion-button{padding: 24px 20px;}
.faqTabs .accordion-button p {margin-bottom:0px;}
.faqTabs .accordion-button::after{display: none;}
.accordionArrow{opacity: 0.4;}
.videoLinkBox{border-radius: 10px;background-color: #393C48;max-width: 400px;}
.videoLinkImg figure{ height: 230px;}
.videoLinkImg figure img{width: 100%; height:100%; object-fit: cover;border-radius: 10px;}
.vidoDetails{padding: 20px 30px 30px 30px;}
.vidoDetails h5{ font-size: 20px; line-height: 25px; font-weight: 400; margin-bottom: 20px; opacity: 1;}
.vidoDetails p{ font-size: 14px; line-height: 18px; opacity: 0.4;}
.vidoDetails a{ font-size: 16px; line-height: 20px; color: #4B91F0;}
.vidoDetails a:hover{color: #fff;opacity: 0.4;}
.clipboardDiv{display: flex; align-items: center;}
.clipboardDiv a {margin-right: 60px;}
.clipboardDiv svg{opacity: 0.6;}
/* Tab Round Button */
.tabTitle{font-size: 14px; line-height: 18px; font-weight: 500; color: #fff; margin-bottom: 5px;text-align: left;}
.tickCircle svg{color: #fff; font-size: 13px;}
.animatedTab .nav-tabs .nav-link.active .tabTitle{color: #FD6F21;}
.animatedTab .nav-tabs .nav-item{display: flex; align-items: center; }
.animatedTab .nav-tabs .nav-item:not(:last-child){ width: 100%;}
.animatedTab .nav-tabs .nav-link, .animatedTab .nav-tabs .nav-link.active { background: transparent; padding: 0px; border: 0px; }
.animatedTab .nav-tabs .nav-link .tickCircle{width: 30px; height: 30px; background: transparent; border-radius: 50%; border: 2px solid #6468662e; color: transparent; padding: 0px; display: flex; align-items: center; justify-content: flex-start;}
.animatedTab .nav-tabs .nav-link.active .tickCircle{background: #FD6F21; border: 0px solid #fff; color: #FD6F21; padding: 0px;}
.animatedTab .nav-tabs .nav-link .tickCircle{width: 30px; height: 30px; background: transparent; border-radius: 50%; border: 2px solid #6468662e; color: transparent; padding: 0px; display: flex; align-items: center; justify-content: center;}
.circleBef{display: flex; align-items: center;}
.animatedTab .nav-tabs .nav-item:not(:last-child) .circleBef::after{content:''; display:block; height: 1px; width: 100%; background-color: #6468662e; border: 1px solid #6468662e;}
.tabButton, .animatedTab .nav-tabs .nav-link{width: 100%;}
.animatedTab .nav-tabs{border-bottom: 0px; justify-content: center; padding-top: 0px;width: 80%; display: flex; margin: 30px auto auto auto; flex-wrap: nowrap;}
.addGroup .addGroupList{margin-bottom: 20px; display: flex; align-items: center; justify-content: space-between;}
.addGroupList{background: #393C48 ; padding: 24px 20px; border-radius: 10px;}
.h5Title{font-size: 20px; line-height: 25px; font-weight: 400; opacity: 0.8; margin-bottom: 8px;}
.visibilityBox{padding: 26px 20px; background: #393C48; border-radius: 10px; }
.visibilityBox{margin-bottom: 20px;}
.visibilityBox ul{padding-left: 0px; list-style: none;}
.visibilityBox ul li{display: flex; align-items: flex-start; justify-content: flex-start;}
.visibilityBox ul.outer-UL li:not(:last-child){margin-bottom: 20px;}
.visibilityBox ul li::before{content: ''; width: 21px; height: 21px;border: 2px solid #83848B; background-color: transparent; margin-right: 16px;border-radius: 50%;}
.modal .faqP{font-size: 18px; line-height: 22px; opacity: 0.5;}
.faqList p {opacity: 1; margin-bottom: 10px;}
.check-UL{line-height: none; padding-left: 0px;}
.check-UL li {display: flex; align-items: flex-start;}
.check-UL h4{font-weight: 400;}
.checkIcon{color: #00FF00; margin-right: 13px;}
.faq-LeftBorder{border-left: 2px solid #6468662e; padding-left: 20px; height: 100%;}
.andLine{position: relative; opacity: 0.4;}
.andLine h4 { overflow: hidden; text-align: center}
.andLine h4:before, .andLine h4:after { background-color: #fff; content: ""; display: inline-block; height: 2px; position: relative; vertical-align: middle; width: 50%;}
.andLine h4:before { right: 0.5em; margin-left: -50%;}
.andLine h4:after { left: 0.5em; margin-right: -50%;}

@media (max-width:1699px) {
/* Modal width */
.uploadvideo .modal-dialog{max-width: 750px;}
.narrowModel .modal-dialog{max-width: 550px;}
.settingModel .modal-dialog{max-width: 992px;}
.medium-Model .modal-dialog{max-width: 800px;}
.videoModal, .settingModel .modelTabs{height: 450px !important;}
/*  */
.modalBox{padding: 20px 0px;}
.fileGroup{margin-bottom: 20px;}
.settingModel .modelTabs::-webkit-scrollbar { width: 4px; height: 4px; }
.settingModel .modelTabs::-webkit-scrollbar-track , .settingModel .modelTabs::-webkit-scrollbar-thumb, .settingModel .modelTabs::-webkit-scrollbar { border-radius: 3px; }
.modal .theme-btn, .modal button{padding: 13px 20px;}
.modelTabs .nav-pills{min-width: 180px; border-bottom-left-radius: 15px;}
.nav-pills .nav-link{ padding: 15px 0px 15px 25px;}
.settingModel .modal-header{padding: 20px;}
.settingModel .modal-body{padding: 20px 20px 10px 20px}
.settingModel .tab-content{padding: 20px 20px 20px 20px; border-radius: 15px;}
.settingModel h4{font-size: 18px; line-height: 20px; margin-bottom: 10px;}
.settingModel .modelTabs { height: 550px;}
.modal-footer{padding: 15px 10px 10px 10px;}
.modalSelect{max-width: 325px; height: 50px; border-radius: 8px; }
.modalSelect select{ font-size: 14px; line-height: 18px;}
.modalSelect span{ margin-right: 15px;}
.modal-content{padding: 20px 15px 20px 20px; border-radius: 20px;}
.modal-body{padding: 100px 0px;}
.modal-header{padding: 0px 0px 20px 0px; }
.modal-title, .modal-H4{ font-size: 21px; line-height:25px; margin-bottom: 10px;}
.modal p{font-size: 13px; line-height: 19px; }
.modalLightP{font-size: 16px; line-height: 20px; }
.modal button{margin: top 30px;}
.uploadBtn{ width:90px; height:90px; margin: auto auto 20px auto;}
.uploadBtn svg{font-size: 45px;}
.thumbnailImg .videoImg:not(:last-child) {margin-right: 8px;}
.thumbnailImg .videoImg{width: 100px; height: 60px;border-radius: 8px; border-width: 1px;}
.thumbnailImg .videoImg:hover{border: 1px solid #FD6F21;}
.thumbnailImg .videoImg img {border-radius: 8px;}
.fileGroup .fileBlock, .fileGroupList textarea{ padding: 15px 18px; height: 60px; }
.modal .modalLightP{font-size: 16px; line-height: 19px;}
/* TAB */
.innerTabs  .react-tabs__tab-list::after{ left: -20px;}
.innerTabs .react-tabs__tab-list li:not(:last-child){ margin-right: 35px;}
.innerTabs .react-tabs__tab-panel{ padding:20px 0px;}
.innerTabs .react-tabs__tab{ padding-bottom: 8px; }
.innerTabs .react-tabs__tab::before { height: 3px; border-radius: 20px;}
.innerTabs .general{margin-bottom:60px;}
.innerTab-input-group{border-radius: 8px; height: 60px;}
.innerTab-input-group input{font-size: 14px;line-height: 18px;}
.advanceSetting .general{margin-bottom: 24px;}
.laws > div{margin-bottom: 22px;}
.laws h5 {font-size: 14px;line-height: 20px; }
.laws ul{margin-bottom: 20px;} 
.laws ul li:not(:last-child){margin-bottom: 10px;}
.laws ul li::before{ width: 15px; height: 15px;border:1px solid#83848B; background-color: transparent; margin-right: 10px;}
.accountBtn{font-size: 14px;line-height: 18px; }
/* Modal Table */ 
.userName{font-size: 16px; line-height: 22px; margin-bottom: 4px;}
.roleProfile .roleImg{ height: 40px; width: 40px; margin-right: 10px;}
.modalTable table th, .modalTable table td{ padding: 25px 0px 0px 0px;}
.faqTabs .accordion-header, .faqTabs .accordion-item, .faqTabs .accordion-button{ border-radius: 8px 8px 8px 8px !important; margin-bottom: 15px; }
.faqTabs .accordion-button{padding: 20px 15px;}
.videoLinkBox{max-width: 250px; border-radius: 8px;}
.videoLinkImg figure{ height: 180px;}
.videoLinkImg figure img{border-radius: 8px;}
.vidoDetails{padding: 15px 25px 25px 25px;}
.vidoDetails h5{ font-size: 18px; line-height: 22px; margin-bottom: 18px;}
.vidoDetails p{ font-size: 12px; line-height: 16px; }
.vidoDetails a{ font-size: 14px; line-height: 18px; }
.clipboardDiv a {margin-right: 30px;}
.addGroup .addGroupList{margin-bottom: 15px;}
.addGroupList{ padding: 20px 15px; border-radius: 8px;}
.h5Title{font-size: 16px; line-height: 19px; margin-bottom: 5px;}
.visibilityBox{padding: 20px 15px; border-radius: 8px; }
.visibilityBox{margin-bottom: 15px;}
.visibilityBox ul.outer-UL li:not(:last-child){margin-bottom: 15px;}
.visibilityBox ul li::before{width: 13px; height: 13px; border: 1px solid #83848B; margin-right: 10px;}
}

@media (max-width:1399px) {
.settingModel .modal-dialog{max-width: 950px;}
}
@media (max-width:992px) {
    .modelTabs .nav-pills{min-width: 150px;}
}
@media (max-width:776px) {
.videoLinkBox{max-width: 100%;}
}
@media (max-width:575px) {
.modelTabs .nav-pills{position: unset;border-radius: 0px;}
.sideTab{width: 100%;}
.medium-Model .modal-dialog{padding: 20px;}
.innerTabs .react-tabs__tab-list li{margin: 0px 10px 10px 10px;}
.animatedTab .nav-tabs .nav-item:not(:last-child):after{min-width: 120px;}
 }
 @media (max-width:475px) {
.animatedTab .nav-tabs .nav-item:not(:last-child):after{min-width: 80px;}
}
@media (max-width:375px) {
 .animatedTab .nav-tabs .nav-item:not(:last-child):after{min-width: 40px;}
}